import React, { Component } from 'react';
import Comments from './comments'
import BlogSingleHeader from '../global-components/blog-single-header'
import singleBlogdata from '../../data/single-blogdata'

class SingleBlog extends Component {
    render () {

        let getdata = singleBlogdata.singlepostdata;
        let publicUrl = process.env.PUBLIC_URL
        let exampleLink = '#'
        let appMobile = 'https://github.com/capcom6/android-sms-gateway/releases/download/v1.24.0/app-release.apk'
        let appWin = 'https://drive.google.com/drive/folders/19_x9xmKGP9NesnqTzXzS-YkH_-PtbIJ9'
        return (
            <div>
                <BlogSingleHeader data={singleBlogdata} />
            <div id="blog" className="pt100 pb100">
            <div className="container">
                <div className="row">
                    <div className="col-sm-10 offset-sm-1">
                    <div className="blog-post">	
                            {/* <aside className="post-thumb">
                                <img src={publicUrl+getdata.image1} alt={getdata.title} />
                            </aside> */}
                            <div className="post-content post-content-full">
                                <h1/>
                                <h3><a href={appWin} target="_blank" rel="noopener noreferrer">Tải về ứng dụng cho máy tính Windown ở đây</a></h3>
                                <p>Giải nén sau đó ấn vào ứng dụng, đợi tầm 20s để khởi động</p>

                                <aside className="post-thumb">
                                    <img src={publicUrl+getdata.image1} alt={getdata.title} />
                                </aside>
                                
                                
                                <h4></h4>

                                <h3><a href={appMobile} target="_blank" rel="noopener noreferrer">Tải về ứng dụng cho điện thoại androi ở đây</a></h3>
                                <h5>Làm theo các bước sau</h5>
                                <p>Tải về, sau đó bấm vào để cài đặt</p>
                                <aside className="post-thumb2">
                                    <img src={publicUrl+getdata.image5} alt={getdata.title} />
                                    --
                                    <img src={publicUrl+getdata.image10} alt={getdata.title} />
                                </aside>
                                <p>Mở ứng dụng ấn cho phép</p>
                                <aside className="post-thumb2">
                                    <img src={publicUrl+getdata.image9} alt={getdata.title} />
                                    --
                                    <img src={publicUrl+getdata.image8} alt={getdata.title} />
                                </aside>
                                <p>Bật "Local server" sau đó bật online lên</p>
                                <aside className="post-thumb2">
                                    <img src={publicUrl+getdata.image11} alt={getdata.title} />
                                    --
                                    <img src={publicUrl+getdata.image7} alt={getdata.title} />
                                </aside>
                                <p>Hiện ra "Local Address" như dưới đây là được</p>
                                <aside className="post-thumb2">
                                    <img src={publicUrl+getdata.image6} alt={getdata.title} />
                                </aside>

                                <h3>Chuyển qua ứng dụng trên máy tính</h3>
                                <ul>
                                    <li><a href={exampleLink}>Đăng nhập vào app trên máy tính</a></li>
                                    <li><a href={exampleLink}>Nhập các thông tin cần thiết từ app điện thoại vào</a></li>
                                    <li><a href={exampleLink}>Thêm file excel chứa sdt từ máy tính vào</a></li>
                                    <li><a href={exampleLink}>Chọn cột chứa sdt</a></li>
                                    <li><a href={exampleLink}>Ấn gửi tin nhắn</a></li>
                                </ul>
                                
                                <aside className="post-thumb">
                                    <img src={publicUrl+getdata.image2} alt={getdata.title} />
                                </aside>

                                <h4>Khi thông báo gửi tất cả hoàn thành</h4>
                                <h4>Kiểm tra trên phần "Message" của app điện thoại</h4>
                                <h4>Xem các tin nhắn nào đã được gửi thành công</h4>


                                
                                
                                
                                
                                {/* <h4></h4>
                                <h5>This is heading five</h5>
                                <h6>This is heading six</h6>
                                <h3>Key Features</h3>
                                <ul>
                                    <li>Responsive design</li>
                                    <li>Unique Design</li>
                                    <li>Bootstrap framework</li>
                                    <li>CSS3 Animations</li>
                                    <li>Pixel perfect design</li>
                                    <li>Working Contact Form</li>
                                </ul>
                                <h3>About Bumb</h3>
                                <ul>
                                    <li><a href={exampleLink}>Blog page included</a></li>
                                    <li><a href={exampleLink}>W3C Valid HTML</a></li>
                                    <li><a href={exampleLink}>Very Easy to Customize</a></li>
                                    <li><a href={exampleLink}>Google fonts used</a></li>
                                    <li><a href={exampleLink}>SEO friendly</a></li>
                                    <li><a href={exampleLink}>Well documented</a></li>
                                </ul> */}
                                {/* <div className="row">
                                    <div className="col-md-6">
                                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat.</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat.</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        {/* <div className="blog-post">	
                            <aside className="post-thumb">
                                <img src={publicUrl+getdata.image} alt={getdata.title} />
                            </aside>
                            <div className="post-content post-content-full">
                                <p>Tải về ứng dụng ở đây</p>
                                <blockquote>Hoặc ở đây</blockquote>
                                <h1>This is heading one</h1>
                                <h2>This is heading two</h2>
                                <h3>This is heading three</h3>
                                <h4>This is heading four</h4>
                                <h5>This is heading five</h5>
                                <h6>This is heading six</h6>
                                <h3>Key Features</h3>
                                <ul>
                                    <li>Responsive design</li>
                                    <li>Unique Design</li>
                                    <li>Bootstrap framework</li>
                                    <li>CSS3 Animations</li>
                                    <li>Pixel perfect design</li>
                                    <li>Working Contact Form</li>
                                </ul>
                                <h3>About Bumb</h3>
                                <ul>
                                    <li><a href={exampleLink}>Blog page included</a></li>
                                    <li><a href={exampleLink}>W3C Valid HTML</a></li>
                                    <li><a href={exampleLink}>Very Easy to Customize</a></li>
                                    <li><a href={exampleLink}>Google fonts used</a></li>
                                    <li><a href={exampleLink}>SEO friendly</a></li>
                                    <li><a href={exampleLink}>Well documented</a></li>
                                </ul>
                                <div className="row">
                                    <div className="col-md-6">
                                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat.</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat.</p>
                                    </div>
                                </div>
                            </div>
                            <footer className="blog-footer text-center">
                                <span className="cat-links">
                                <i className="fa fa-folder"></i> Posted in: <a href={exampleLink}>Apps</a>, <a href={exampleLink}>Design</a>, <a href={exampleLink}>Creative</a>
                                </span>
                                <span className="tags-links">
                                    <i className="fa fa-tags"></i> Tagged: <a href={exampleLink}>app</a>, <a href={exampleLink}>google</a>, <a href={exampleLink}>test</a>
                                </span>
                            </footer>
                        </div> */}
                        <div className="clearfix"></div>
                        {/* <div className="row nav-links">
                            <div className="col-md-6">
                                <div className="nav-previous text-left">
                                    <a href={exampleLink}>
                                        <span><i className="fa fa-angle-left"></i> Previous </span>
                                        <h5>Modern Apps With Amazing Features</h5>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="nav-next text-right">
                                    <a href={exampleLink}>
                                        <span>Next <i className="fa fa-angle-right"></i></span>
                                        <h5>Modern Apps With Amazing Features</h5>
                                    </a>
                                </div>
                            </div>
                        </div> */}
                        {/** Comments Area **/}
                        {/* <Comments /> */}

                    </div>
                </div>
            </div>
        </div>
        </div>
        )
    }
}


export default SingleBlog
